import React, { Component, createRef } from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  Card,
  // Customizable Area End
} from "@mui/material";
import Spinner from "./components/Spinner.web";
import CustomizedSteppers from "./components/Stepper.web";
interface State {
  otp: string;
  isResendEnabled: boolean;
  resendTime: number;
  phoneNumber: string;
  otpError: boolean;
}
const { baseURL } = require("../../../../packages/framework/src/config.js");
import i18n from "../../../framework/src/languageSupport/i18n.web";

export const configJSON = require("./config.js");
class OtpVerification extends Component<{}, State> {
  private resendInterval: ReturnType<typeof setInterval> | null = null;
  private inputRefs: React.RefObject<HTMLInputElement>[] = Array(6)
    .fill(null)
    .map(() => createRef<HTMLInputElement>());

  constructor(props: {}) {
    super(props);
    this.state = {
      otp: "",
      isResendEnabled: false,
      resendTime: 60,
      phoneNumber: "",
      otpError: false,
    };
  }

  componentDidMount() {
    this.startResendTimer(false);
  }

  componentWillUnmount() {
    if (this.resendInterval) {
      clearInterval(this.resendInterval);
    }
  }

  startResendTimer = async (resendEnable: boolean) => {
    if (resendEnable) {
      await this.resendOtp();
    }
    this.setState({ isResendEnabled: false, resendTime: 60 });
    this.resendInterval = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.resendTime <= 1) {
          clearInterval(this.resendInterval!);
          return { isResendEnabled: true, resendTime: 0 };
        }
        return {
          isResendEnabled: prevState.isResendEnabled,
          resendTime: prevState.resendTime - 1,
        };
      });
    }, 1000);
  };

  handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = event.target;
    if (/^[0-9]{0,1}$/.test(value)) {
      const otpArray = this.state.otp.split("");
      otpArray[index] = value;
      this.setState({ otp: otpArray.join("") }, () => {
        if (value && index < this.inputRefs.length - 1) {
          this.inputRefs[index + 1].current?.focus();
        }
      });
    }
  };
  handleNext = () => {
    const { navigation }: any = this.props;
    const { navigate }: any = navigation;
    navigate("ComplaintFormCallerDetails", { navigation });
  };

  handlePrev = () => {
    const { navigation }: any = this.props;
    navigation.goBack();
  };

  handleSubmit = async () => {
    const { otp, phoneNumber } = this.state; // Get phone number from state

    const payload = {
      pin: otp,
      type: "complain", // Adjust the type if needed
      phone_number: localStorage.getItem("phoneNumber"),
    };

    const apiBaseUrl = baseURL;

    const finalApi = `${apiBaseUrl}/bx_block_phone_verification/phone_verifications/verify_sms_otp`;
    try {
      const response = await fetch(finalApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("OTP verified successfully:", data);
        localStorage.setItem("authToken", data.token);
        this.setState({ otpError: false });
        // Handle successful verification (e.g., navigate to the next screen)
        this.handleNext();
      } else {
        console.error("Error verifying OTP:", response.statusText);
        // this.handleNext();
        this.setState({ otpError: true });
      }
    } catch (error) {
      console.error("Network error:", error);
      this.setState({ otpError: true });
    }
  };

  resendOtp = async () => {
    const { otp, phoneNumber } = this.state; // Get phone number from state

    const payload = {
      user_id: localStorage.getItem("userId"), // Adjust the type if needed
      phone_number: localStorage.getItem("phoneNumber"),
    };

    const apiBaseUrl = baseURL;

    const finalApi = `${apiBaseUrl}/bx_block_phone_verification/phone_verifications/resend_otp`;

    try {
      const response = await fetch(finalApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("OTP sent successfully:", data);
        // Handle successful verification (e.g., navigate to the next screen)
        // this.handleNext();
      } else {
        console.error("Error send OTP:", response.statusText);
        // this.handleNext();
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  render() {
    const { otp, isResendEnabled, resendTime } = this.state;
    const isOtpComplete = otp.length === 6;
    const steps =
      localStorage.getItem("preferredLanguage") == "English"
        ? ["Personal Information", "Phone Verification", "Complaint Details"]
        : ["المعلومات الشخصية", "التحقق من الهاتف", "بيانات الشكوى"];
    const activeStep = 1;
    return (
      <Box sx={webStyle.mainWrapper}>
        {/* {this.state.loading && <Spinner spinnerModal={this.state.loading} />} */}
        <Box
          display={{ xs: "block", sm: "flex" }}
          justifyContent="space-between"
          mb="40px"
        ></Box>

        <Box padding={{ xs: "15px", sm: "20px", md: "30px" }}>
          <Typography sx={webStyle.titleText}>
            {i18n.t("otppage.complaintTitle")}
          </Typography>
          <Typography sx={webStyle.bodyText}>
            {i18n.t("otppage.complaintDescription")}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <CustomizedSteppers steps={steps} activeStep={activeStep} />

            <Card
              style={{
                width: "600px",
                height: "261.22px",
                border: "1px",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Typography
                style={{ fontFamily: "Cairo", fontWeight: "900" }}
                variant="h5"
              >
                {i18n.t("otppage.otpVerificationTitle")}
              </Typography>
              <Typography
                style={{
                  fontFamily: "Cairo",
                  fontWeight: "900",
                  color: "#868E96",
                }}
                variant="body1"
              >
                {i18n.t("otppage.otpVerificationMessage")}{" "}
                {localStorage.getItem("phoneNumber")}{" "}
              </Typography>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                {[0, 1, 2, 3, 4, 5].map((_, index) => (
                  <TextField
                    key={index}
                    type="text"
                    inputProps={{ maxLength: 1 }}
                    value={otp[index] || ""}
                    onChange={(event) =>
                      this.handleChange(
                        event as React.ChangeEvent<HTMLInputElement>,
                        index
                      )
                    }
                    variant="outlined"
                    style={{
                      width: "50.38px",
                      margin: "0 5px",
                      fontFamily: "Cairo",
                      backgroundColor: "#EFF5FA",
                    }}
                    inputRef={this.inputRefs[index]} // Assign the ref to the input
                  />
                ))}
              </Box>
              <Typography
                variant="body2"
                style={{
                  marginTop: "20px",
                  fontFamily: "Cairo",
                  fontWeight: "900",
                  color: "#999999",
                }}
              >
                {i18n.t("otppage.didNotReceiveCodeText")}{" "}
                {isResendEnabled ? (
                  <Button
                    onClick={() => this.startResendTimer(true)}
                    variant="text"
                    color="primary"
                    style={{
                      fontFamily: "Cairo",
                      color: "#FF5C35",
                    }}
                  >
                    {i18n.t("otppage.resendCodeText2")}
                  </Button>
                ) : (
                  <span
                    style={{
                      fontFamily: "Cairo",
                      color: "#FF5C35",
                    }}
                  >
                    {i18n.t("otppage.resendTimerText")}
                    {resendTime < 10 ? `0${resendTime}` : resendTime}
                  </span>
                )}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  marginTop: "20px",
                  fontFamily: "Cairo",
                  fontWeight: "900",
                  color: "#999999",
                }}
              >
                {this.state.otpError ? (
                  <span
                    style={{
                      fontFamily: "Cairo",
                      color: "#FF5C35",
                    }}
                  >
                    {i18n.t("otppage.invalidPIN")}
                  </span>
                ) : null}
              </Typography>
            </Card>

            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Button
                onClick={this.handlePrev}
                variant="outlined"
                sx={{
                  fontFamily: "Cairo",
                  color: "#CBA344",
                  borderColor: "#CBA344",
                  marginRight: "20px",
                  width: "108px",
                  "&:hover": {
                    backgroundColor: "#A88633",
                    borderColor: "#A88633",
                  },
                }}
              >
                {i18n.t("otppage.previousButtonText")}
              </Button>
              <Button
                onClick={this.handleSubmit}
                variant="contained"
                disabled={!isOtpComplete}
                sx={{
                  fontFamily: "Cairo",
                  color: "#fff",
                  borderColor: "#CBA344",
                  marginRight: "20px",
                  width: "108px",
                  backgroundColor: "#CBA344",
                  "&:hover": {
                    backgroundColor: "#A88633",
                    borderColor: "#A88633",
                  },
                }}
              >
                {i18n.t("otppage.nextButtonText")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

const webStyle = {
  mainWrapper: {
    fontFamily: "Cairo",
    padding: { xs: "10px", md: "20px" },
  },
  titleText: {
    fontSize: { xs: "24px", sm: "32px", md: "40px" }, // Responsive font size
    paddingVertical: 10,
    fontWeight: "600",
    fontFamily: "Cairo",
  },
  bodyText: {
    fontSize: { xs: "14px", sm: "16px", md: "18px" }, // Responsive font size
    paddingVertical: 15,
    fontWeight: "400",
    fontFamily: "Cairo",
  },
};

export default OtpVerification;

import React from 'react';
import AlertBlockController from './AlertBlockController';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Box } from '@material-ui/core';
import { Props } from './AlertBlockController';
import i18n from '../../../framework/src/languageSupport/i18n.web';


export default class AlertBlock extends AlertBlockController {
  constructor(props: Props) {
    super(props);
  }

  getNeutralButton() {
    const neutralButton = this.state.buttons.find(button => button.type === "neutral");
    if (!neutralButton) {
      return null;
    }
    return (
      <Button onClick={() => this.onButtonPress(neutralButton)}>
        {neutralButton.label}
      </Button>
    )
  }

  render() {
    if (!this.state.title && !this.state.body) {
      return null;
    }

    return (
      <div>

        {
          this.state.type != "complaint" ? <div>
            <Dialog
              open={this.state.isVisible}
              onClose={() => this.setState({ isVisible: false })}
              PaperProps={{
                style: {
                  display: "flex",
                  width: "600px",
                  paddingTop: "20px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "40px",
                  background: "#FFF",
                  boxShadow: "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)"
                }
              }}
            >
              <DialogTitle>
                {this.state.title == "Under Review" &&
                  <Box style={{ "width": "60px", "height": "60px" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M30 5C16.2 5 5 16.2 5 30C5 43.8 16.2 55 30 55C43.8 55 55 43.8 55 30C55 16.2 43.8 5 30 5ZM40.6 19.4C37.675 16.475 33.85 15 30 15V30L19.4 40.6C25.25 46.45 34.75 46.45 40.625 40.6C46.475 34.75 46.475 25.25 40.6 19.4ZM10 30C10 41.05 18.95 50 30 50C41.05 50 50 41.05 50 30C50 18.95 41.05 10 30 10C18.95 10 10 18.95 10 30Z" fill="#0F172A" />
                    </svg>
                  </Box>
                }

                {this.state.title == "Success" &&
                  <Box style={{ "display": "flex", "padding": "12px", "flexDirection": "column", "justifyContent": "center", "alignItems": "center", "borderRadius": "99px", "background": "var(--Primary-AE-Green-100);" }}>

                    {/* <Box style={{ "width": "60px", "height": "60px" }}> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                      <path d="M29.2071 9.70708L13.2071 25.7071C13.1142 25.8001 13.0039 25.8738 12.8825 25.9241C12.7611 25.9745 12.631 26.0004 12.4996 26.0004C12.3682 26.0004 12.238 25.9745 12.1166 25.9241C11.9952 25.8738 11.885 25.8001 11.7921 25.7071L4.79208 18.7071C4.60444 18.5194 4.49902 18.2649 4.49902 17.9996C4.49902 17.7342 4.60444 17.4797 4.79208 17.2921C4.97972 17.1044 5.23422 16.999 5.49958 16.999C5.76494 16.999 6.01944 17.1044 6.20708 17.2921L12.4996 23.5858L27.7921 8.29208C27.9797 8.10444 28.2342 7.99902 28.4996 7.99902C28.7649 7.99902 29.0194 8.10444 29.2071 8.29208C29.3947 8.47972 29.5001 8.73422 29.5001 8.99958C29.5001 9.26494 29.3947 9.51944 29.2071 9.70708Z" fill="#3F8E50" />
                    </svg>
                  </Box>
                }
              </DialogTitle>
              <DialogContent>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography
                    variant="body1"
                    style={{
                      width: "270px",
                      flexShrink: "0",
                      color: "var(--Gray-Gray-10, #0F1106)",
                      textAlign: "center",
                      fontFamily: "Cairo",
                      fontSize: "32px",
                      fontStyle: "normal",
                      fontWeight: "900",
                      lineHeight: "140%",
                      letterSpacing: "0.16px"
                    }}
                  >
                    {this.state.title}
                  </Typography>
                  <Typography
                    style={{
                      flexShrink: "0",
                      alignSelf: "stretch",
                      color: "#909090",
                      textAlign: "center",
                      fontFamily: "Cairo",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "28px"
                    }}
                  >
                    {this.state.body}
                  </Typography>
                </Box>
              </DialogContent>
              <DialogActions style={{ display: 'flex', flexDirection: 'column' }}>
                {this.getNeutralButton()}
                <div style={{ "display": "flex", "height": "104px", "padding": "24px", "justifyContent": "center", "alignItems": "center", "gap": "16px", "alignSelf": "stretch", "borderRadius": "0px 0px 32px 8px", "borderTop": "1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)", "background": "var(--Basic-White, #FFF)" }}>
                  {this.state.buttons
                    .filter(button => button.type !== "neutral")
                    .map((button, index) => (
                      <Button
                        key={index}
                        onClick={() => this.onButtonPress(button)}
                        style={{ "display": "flex", "width": "172.73px", "height": "56px", "padding": "16px", "justifyContent": "center", "alignItems": "center", "gap": "8px", "border": "1px solid #CBA344" }}>

                        <Typography style={{ "color": "#CBA344", "fontFamily": "Cairo", "fontSize": "20px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "24px" }}>{i18n.t('dialogueBox.okloseButtonText')}</Typography>
                      </Button>
                    ))}
                </div>
              </DialogActions>
            </Dialog>
          </div> : <div>
            <Dialog
              open={this.state.isVisible}
              onClose={() => this.setState({ isVisible: false })}
              PaperProps={{
                style: { "display": "inline-flex", "padding": "24px", "flexDirection": "column", "alignItems": "center", "gap": "24px", "borderRadius": "8px", "background": "#FFF", "boxShadow": "0px 10px 15px -3px rgba(27, 29, 33, 0.10), 0px 4px 6px -4px rgba(27, 29, 33, 0.10)" }
              }}
            >
              <DialogTitle>
                {this.state.title == "Success" &&
                  <Box style={{ "display": "flex", "padding": "12px", "flexDirection": "column", "justifyContent": "center", "alignItems": "center", "borderRadius": "99px", "background": "var(--Primary-AE-Green-100, #E4F4E7)" }}>

                    {/* <Box style={{ "width": "60px", "height": "60px" }}> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                      <path d="M29.2071 9.70708L13.2071 25.7071C13.1142 25.8001 13.0039 25.8738 12.8825 25.9241C12.7611 25.9745 12.631 26.0004 12.4996 26.0004C12.3682 26.0004 12.238 25.9745 12.1166 25.9241C11.9952 25.8738 11.885 25.8001 11.7921 25.7071L4.79208 18.7071C4.60444 18.5194 4.49902 18.2649 4.49902 17.9996C4.49902 17.7342 4.60444 17.4797 4.79208 17.2921C4.97972 17.1044 5.23422 16.999 5.49958 16.999C5.76494 16.999 6.01944 17.1044 6.20708 17.2921L12.4996 23.5858L27.7921 8.29208C27.9797 8.10444 28.2342 7.99902 28.4996 7.99902C28.7649 7.99902 29.0194 8.10444 29.2071 8.29208C29.3947 8.47972 29.5001 8.73422 29.5001 8.99958C29.5001 9.26494 29.3947 9.51944 29.2071 9.70708Z" fill="#3F8E50" />
                    </svg>
                  </Box>
                  // </Box>

                }
                {this.state.title == "Failed" &&
                  <Box style={{ "display": "flex", "padding": "12px", "flexDirection": "column", "justifyContent": "center", "alignItems": "center", "borderRadius": "99px", "background": "var(--Primary-Red-100, #FEE2E2)" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                      <path d="M24.9 7.61328C24.6509 7.3636 24.3127 7.22329 23.96 7.22329C23.6073 7.22329 23.2691 7.3636 23.02 7.61328L16.5 14.1199L9.97996 7.59994C9.73085 7.35027 9.39265 7.20996 9.03996 7.20996C8.68727 7.20996 8.34907 7.35027 8.09996 7.59994C7.57996 8.11994 7.57996 8.95994 8.09996 9.47994L14.62 15.9999L8.09996 22.5199C7.57996 23.0399 7.57996 23.8799 8.09996 24.3999C8.61996 24.9199 9.45996 24.9199 9.97996 24.3999L16.5 17.8799L23.02 24.3999C23.54 24.9199 24.38 24.9199 24.9 24.3999C25.42 23.8799 25.42 23.0399 24.9 22.5199L18.38 15.9999L24.9 9.47994C25.4066 8.97327 25.4066 8.11994 24.9 7.61328Z" fill="#DC2626" />
                    </svg>
                  </Box>
                }
              </DialogTitle>
              <DialogContent>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography
                    variant="body1"
                    style={{
                      width: "270px",
                      flexShrink: "0",
                      color: "var(--Gray-Gray-10, #0F1106)",
                      textAlign: "center",
                      fontFamily: "Cairo",
                      fontSize: "32px",
                      fontStyle: "normal",
                      fontWeight: "900",
                      lineHeight: "140%",
                      letterSpacing: "0.16px",
                      paddingBottom: "10px"
                    }}
                  >
                    {this.state.title}
                  </Typography>
                  <Typography
                    style={{
                      flexShrink: "0",
                      alignSelf: "stretch",
                      color: "#909090",
                      textAlign: "center",
                      fontFamily: "Cairo",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "28px",
                      paddingBottom: "20px"

                    }}
                  >
                    {this.state.body}
                  </Typography>
                </Box>

                {
                  this.state.complaintId && <div style={{ "display": "flex", "padding": "12px 24px",paddingBottom: "20px", "justifyContent": "center", "alignItems": "center", "gap": "12px", "alignSelf": "stretch", "borderRadius": "8px", "border": "1px solid #CBA344" }}>
                    <Typography style={{ "color": "#CBA344", "fontFamily": "Cairo", "fontSize": "20px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "24px" }}>{i18n.t('dialogueBox.complaintId')} {this.state.complaintId}</Typography>
                  </div>
                }


              </DialogContent>
              <DialogActions style={{ display: 'flex', flexDirection: 'column' }}>
                {this.getNeutralButton()}
                <div style={{"display":"flex","padding":"12px 24px","justifyContent":"center","alignItems":"center","gap":"12px","alignSelf":"stretch","borderRadius":"8px","background":"#CBA344"}}>
                  {this.state.buttons
                    .filter(button => button.type !== "neutral")
                    .map((button, index) => (
                      <Button
                        key={index}
                        onClick={() => this.onButtonPress(button)}
                        >

                        <Typography style={{"color":"var(--Pure-White-50, #FFF)","textAlign":"center","fontFamily":"Cairo","fontSize":"16px","fontStyle":"normal","fontWeight":"500","lineHeight":"24px"}}>{i18n.t('dialogueBox.closeButtonText')}</Typography>
                      </Button>
                    ))}
                </div>
              </DialogActions>
            </Dialog>
          </div>
        }

      </div>

    );
  }
}
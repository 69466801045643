// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import Rolesandpermissions2 from '../../blocks/rolesandpermissions2/src/Rolesandpermissions2';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Emailnotifications2 from '../../blocks/emailnotifications2/src/Emailnotifications2';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import TermsConditions from '../../blocks/termsconditions/src/TermsConditions';
import TermsConditionsDetail from '../../blocks/termsconditions/src/TermsConditionsDetail';
import TermsConditionsUsers from '../../blocks/termsconditions/src/TermsConditionsUsers';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import Captcha from '../../blocks/captcha/src/Captcha';
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import Analytics from '../../blocks/analytics/src/Analytics';
import RequestManagement from '../../blocks/requestmanagement/src/RequestManagement';
import ImportExportData from '../../blocks/importexportdata/src/ImportExportData';
import LanguageOptions from '../../blocks/languageoptions/src/LanguageOptions';
import LanguageSupport from '../../blocks/languagesupport/src/LanguageSupport';
import Adminconsole2 from '../../blocks/adminconsole2/src/Adminconsole2';
import ComplaintFormPersonalDetails from '../../blocks/customform/src/ComplaintFormPersonalDetails';
import ComplaintFormCallerDetails from '../../blocks/customform/src/ComplaintFormCallerDetails';
import OtpVerification from '../../blocks/customform/src/OtpVerification';
import TrackComplaintPersonalInfo from '../../blocks/customform/src/TrackComplaintPersonalInfo.web';
import TrackComplaintOtpVerification from '../../blocks/customform/src/TrackComplaintOtpVerification';
import Footer from '../../components/src/Footer';

const routeMap = {
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems',
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions',
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword',
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP',
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword',
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: '/Rolesandpermissions2',
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector',
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: '/Emailnotifications2',
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock',
  },
  TermsConditions: {
    component: TermsConditions,
    path: '/TermsConditions',
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: '/TermsConditionsDetail',
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: '/TermsConditionsUsers',
  },
  LandingPage: {
    component: LandingPage,
    path: '/',
  },
  Captcha: {
    component: Captcha,
    path: '/Captcha',
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: '/NavigationMenu',
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth',
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories',
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue',
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics',
  },
  RequestManagement: {
    component: RequestManagement,
    path: '/RequestManagement',
  },
  ImportExportData: {
    component: ImportExportData,
    path: '/ImportExportData',
  },
  LanguageOptions: {
    component: LanguageOptions,
    path: '/LanguageOptions',
  },
  LanguageSupport: {
    component: LanguageSupport,
    path: '/LanguageSupport',
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: '/Adminconsole2',
  },

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  TrackComplaintPersonalInfo: {
    component: TrackComplaintPersonalInfo,
    path: '/track-complaint/personal-information',
  },
  TrackComplaintOtpVerification: {
    component: TrackComplaintOtpVerification,
    path: '/track-complaint/otp-verification',
  },
  ComplaintFormPersonalDetails: {
    component: ComplaintFormPersonalDetails,
    path: '/complaint-form/personal-details',
  },
  ComplaintFormCallerDetails: {
    component: ComplaintFormCallerDetails,
    path: '/complaint-form/caller-details',
  },
  ComplaintOtpVerfication: {
    component: OtpVerification,
    path: '/complaint-form/otp-verfication',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  const languageType = localStorage.getItem('preferredLanguage')

  return (
    <div dir={localStorage.getItem('preferredLanguage') === 'Arabic' ? 'rtl' : 'ltr'}><BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />

        <AlertBlock />
        <Footer />
      </View>
    </BuilderProvider>
    </div>
  );
}

export default App;

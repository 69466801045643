import * as React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Typography, Box } from "@mui/material";
import { StepIconProps } from "@mui/material/StepIcon";

const ColorlibConnector = styled(StepConnector)(({ theme }) => {
  const isArabic = localStorage.getItem("preferredLanguage") === "Arabic";

  return {
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
      ...(isArabic && {
        left: "calc(50% + 20px)",
        right: "calc(-50% + 20px)",
      }),
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#B68A35",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#B68A35",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: "#eaeaf0",
      borderRadius: 1,
      ...theme.applyStyles("dark", {
        backgroundColor: theme.palette.grey[800],
      }),
    },
  };
});

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme }) => ({
  backgroundColor: "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Cairo",
  ...theme.applyStyles("dark", {
    backgroundColor: theme.palette.grey[700],
  }),

  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        backgroundColor: "#B68A35",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        backgroundColor: "#B68A35",
      },
    },
  ],
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className, icon } = props;

  const icons = [
    completed ? <DoneComponent key="1" /> : "1",
    completed ? <DoneComponent key="2" /> : "2",
    completed ? <DoneComponent key="3" /> : "3",
  ];

  // Adjust the index by subtracting 1
  const index = Number(icon) - 1;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[index]}
    </ColorlibStepIconRoot>
  );
}

// Define the props interface
interface CustomizedSteppersProps {
  steps: string[]; // Array of step labels
  activeStep: number; // Current active step index
}

// Accept steps and activeStep as props
export default function CustomizedSteppers({
  steps,
  activeStep,
}: CustomizedSteppersProps) {
  return (
    <Box
      sx={{
        width: {
          xs: "90%",
          sm: "80%",
          md: "70%",
        },
        padding: 2,
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor: "white",
        marginTop: "35px",
        marginBottom: "35px",
        paddingTop: "35px",
        fontFamily: "Cairo",
      }}
    >
      <Stack spacing={4}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography
                  sx={{
                    fontSize: { xs: 16, sm: 18, md: 21 },
                    fontWeight: 600,
                    fontFamily: "Cairo",
                  }}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </Box>
  );
}

const DoneComponent = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="m23.472 2.1-15 15a.937.937 0 0 1-1.326 0L.583 10.539A.938.938 0 1 1 1.91 9.212l5.9 5.9L22.145.774a.938.938 0 0 1 1.326 1.327Z"
    />
  </svg>
);

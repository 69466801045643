import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config.js");
const { baseURL } = require("../../../../packages/framework/src/config.js");

export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface Touched {
  phoneNumber: boolean;
  complaintNumber: boolean;
}

export interface Error {
  complaintNumber: string;
  phoneNumber: string;
}

export interface ResponseJson {
  id: string;
  attributes: {
    complaintNumber: string;
    phone_number: string;
  };
}

export interface Dropdown {
  label: string;
  value: string;
}

export interface Data {
  userId: string;
  complaintNumber: string;
  phone_number: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  complaintNumber: string;
  phoneNumber: string;
  isChange: boolean;
  isVerified: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class TrackComplaintPersonalInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserListApiCallId: string = "";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  complaintInquiryApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      complaintNumber: "",
      phoneNumber: "",
      isChange: false,
      isVerified: false,
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!");
          this.setState({ loading: false });
        } else if (responseJson.errors) {
          toast.error(responseJson.errors);
          this.setState({ loading: false });
        } else if (apiRequestCallId == this.getUserListApiCallId) {
          let filterData = responseJson.data.map((item: ResponseJson) => {
            return {
              userId: item.id,
              complaintNumber: item.attributes.complaintNumber,
              phoneNumber: item.attributes.phone_number,
            };
          });
        } else if (apiRequestCallId == this.addUserApiCallId) {
          toast.success("User added successfully!");
          this.setState({ loading: false });
          this.onCancel();
        } else if (apiRequestCallId == this.editUserApiCallId) {
          toast.success("User edited successfully!");
          this.setState({ loading: false });
          this.onCancel();
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    // this.getUserList();
  }

  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  };

  onEditBtnClicked = (item: Data) => {
    this.setState({
      complaintNumber: item.complaintNumber,
      phoneNumber: item.phone_number,
    });
  };

  onCancel = () => {
    this.setState({
      complaintNumber: "",
      phoneNumber: "",
    });
  };

  complaintInquiry = () => {
    this.setState({ loading: true });

    // Create a new FormData object and append the form fields
    const formData = new FormData();
    formData.append("phone_number", this.state.phoneNumber); // phone_number value from the state
    formData.append("complain_number", this.state.complaintNumber); // complaint_number value from the state
    const apiBaseUrl = baseURL;
    const finalApi = `${apiBaseUrl}/bx_block_complain/complains/track_complain`;
    fetch(finalApi, {
      method: "POST", // HTTP method
      body: formData, // Send FormData as the body
    })
      .then((response) => response.json()) // Parse the JSON response
      .then((data) => {
        console.log("complaintInquiry data",data)
        console.log("complaintInquiry data.user_id",data.user_id)

        localStorage.setItem("phoneNumber", this.state.phoneNumber);
        localStorage.setItem("complain_number", this.state.complaintNumber);
        localStorage.setItem("user_id", data.user_id);


        console.log("Success:", data); // Handle success
        this.setState({ loading: false }); // Update loading state
        this.handleNext();
      })
      .catch((error) => {
        console.error("Error:", error); // Handle error
        this.setState({ loading: false }); // Update loading state
      });
  };

  handleNext = () => {
    const { navigation } = this.props;
    const { navigate }: any = navigation;
    navigate("TrackComplaintOtpVerification", { navigation });
  };
  // Customizable Area End
}

// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  Card,

  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import * as Yup from "yup";
import Select, { ValueType } from "react-select";
import { Formik, FormikErrors, FormikTouched } from "formik";
import Spinner from "./components/Spinner.web";

import { Props, Touched, Error, Dropdown } from "./CustomFormController.web";
import ComplaintFormCallerDetailsController from "./ComplaintFormCallerDetailsController.web";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import CustomizedSteppers from "./components/Stepper.web";
import i18n from "../../../framework/src/languageSupport/i18n.web";

// Customizable Area End

export default class ComplaintFormCallerDetails extends ComplaintFormCallerDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  userSchema = () => {
    if (this.getCurrentLanguage() == "en") {
      return Yup.object().shape({
        phoneNumber: Yup.string().required("*Phone number is required!"),
        complaintAgainst: Yup.string().required(
          "*Complaint against is required!"
        ),
        //: Yup.string().required("*Caller Name is required!"),
        email: Yup.string().email(),
        //   emirateId: Yup.string().required("*Emirate is required!"),
      });
    } else {
      return Yup.object().shape({
        phoneNumber: Yup.string().required("*رقم الهاتف مطلوب!"),
        complaintAgainst: Yup.string().required("*الشكوى ضد مطلوبة!"),
        //  callerName: Yup.string().required("*اسم المتصل مطلوب!"),
        email: Yup.string().email(),
        //   emirateId: Yup.string().required("*الإمارة مطلوبة!"),
      });
    }
  };

  getErrorMessage = (
    touched: FormikTouched<Touched>,
    errors: FormikErrors<Error>,
    value: string
  ) => {
    return (
      touched[value as keyof Error] &&
      errors[value as keyof Error] && (
        <Typography
          style={{
            marginTop: "2px",
            fontSize: "14px",
            color: "#f94b4b",
            fontFamily: "Cairo",
          }}
        >
          {errors[value as keyof Error]}
        </Typography>
      )
    );
  };

  getValue = (event: ValueType<Dropdown, false>) => {
    return event ? event.value : "";
  };

  handleClickOpen = () => {
    this.onValueChange("showDialog", true);
  };

  handleClose = () => {
    this.onValueChange("showDialog", false);
  };

  // Customizable Area End

  IndividualBorder() {
    const isEnglish = localStorage.getItem("preferredLanguage") === "English";

    return (
<Card
  style={{
    width: "600px",
    height: "360.7px",
    border: "1px",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }}
>
  <Typography
    style={{ fontSize: "20px", fontFamily: "cairo", fontWeight: "900" }}
  >
    {i18n.t("complaintForm3.ReportNumber")}
  </Typography>

  {/* Point 1 */}
  <Box
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: isEnglish ? "10px" : "0px",  // Padding for RTL
      paddingRight: isEnglish ? "0px" : "10px", // Padding for LTR
      width: "100%", // Ensure full width to align text properly
    }}
  >
    <Typography
      style={{
        fontSize: "32px",
        fontFamily: "cairo",
        fontWeight: "900",
        textAlign: isEnglish ? "right" : "left", // Adjust text alignment based on language
        color: " #B68A35",
        paddingRight: isEnglish ? "10px" : "0px", // Add space for RTL
        paddingLeft: isEnglish ? "0px" : "10px",  // Add space for LTR
      }}
    >
      1
    </Typography>
    <Typography
      style={{
        fontSize: "16px",
        fontFamily: "cairo",
        fontWeight: "400",
        textAlign: isEnglish ? "right" : "left", // Adjust text alignment based on language
      }}
    >
      {i18n.t("complaintForm3.reportNoMessage1")}
    </Typography>
  </Box>

  {/* Point 2 */}
  <Box
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: isEnglish ? "10px" : "0px",  // Padding for RTL
      paddingRight: isEnglish ? "0px" : "10px", // Padding for LTR
      width: "100%", // Ensure full width to align text properly
    }}
  >
    <Typography
      style={{
        fontSize: "32px",
        fontFamily: "cairo",
        fontWeight: "900",
        textAlign: isEnglish ? "right" : "left", // Adjust text alignment based on language
        color: " #B68A35",
        paddingRight: isEnglish ? "10px" : "0px", // Add space for RTL
        paddingLeft: isEnglish ? "0px" : "10px",  // Add space for LTR
      }}
    >
      2
    </Typography>
    <Typography
      style={{
        fontSize: "16px",
        fontFamily: "cairo",
        fontWeight: "400",
        textAlign: isEnglish ? "right" : "left", // Adjust text alignment based on language
      }}
    >
      {i18n.t("complaintForm3.reportNoMessage2")}
    </Typography>
  </Box>
</Card>


    );
  }

  render() {
    const steps =
      this.getCurrentLanguage() == "en"
        ? ["Personal Information", "Phone Verification", "Confirmation"]
        : ["المعلومات الشخصية", "التحقق من الهاتف", "التأكيد"];
    const activeStep = 2;
    return (
      // Customizable Area Start
      <Box sx={webStyle.mainWrapper}>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <Box
          display={{ xs: "block", sm: "flex" }}
          justifyContent="space-between"
          mb="40px"
        ></Box>

        <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
          <Typography style={webStyle.titleText}>
            {i18n.t("complaintForm3.complaintTitle")}
          </Typography>
          <Typography style={webStyle.bodyText}>
            {i18n.t("complaintForm3.complaintDescription")}
          </Typography>
          <Formik
            data-test-id="formik"
            initialValues={{
              prefix: this.state.prefix,
              callerName: this.state.callerName,
              phoneNumber: this.state.phoneNumber,
              email: this.state.email,
              emirateId: this.state.emirateId,
              complaintAgainst: this.state.complaintAgainst,
              dateOfCall: this.state.dateOfCall,
              timeOfCall: this.state.timeOfCall,
            }}
            validationSchema={this.userSchema}
            onSubmit={(values) => {
              this.submitComplaint();
            }}
          >
            {({ errors, touched, setFieldValue, handleSubmit, values }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <CustomizedSteppers steps={steps} activeStep={activeStep} />

                <Grid
                  container
                  spacing={2}
                  direction="column"
                  style={{
                    maxWidth: "410px", // Set the max width to match child elements
                    margin: "0 auto", // Center horizontally
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        marginBottom: "5px",
                        fontSize: "16px",
                        fontFamily: "Cairo",
                      }}
                    >
                      {i18n.t("complaintForm3.complaintAgainstLabel")}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Select
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: "#B68A35", // Set border color to B68A35 when focused
                          "&:hover": {
                            borderColor: "#B68A35", // Maintain the border color on hover
                          },
                          height: "56px", // Match TextField height
                          maxWidth: "410px",
                          width: "100%",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          backgroundColor: "#FFF", // Change the dropdown background color to match the theme
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#B68A35"
                            : state.isFocused
                            ? "#FFF7E8"
                            : provided.backgroundColor, // Custom background on select/focus
                          color: state.isSelected ? "#FFFFFF" : "#000000", // Change text color when selected
                          "&:hover": {
                            backgroundColor: "#FFF7E8", // Change hover background color
                            color: "#000000",
                          },
                        }),
                      }}
                      data-test-id="complaint-against"
                      classNamePrefix="react-select"
                      placeholder={i18n.t("complaintForm3.company")}
                      isSearchable={false}
                      value={
                        complaint_category.filter(
                          (option: Dropdown) =>
                            option.value === this.state.complaintAgainst
                        )[0]
                      }
                      options={complaint_category}
                      onChange={(event) => {
                        const value = this.getValue(event);
                        this.onValueChange("complaintAgainst", value);
                        setFieldValue("complaintAgainst", value);
                      }}
                    />

                    {this.getErrorMessage(touched, errors, "complaintAgainst")}
                  </Grid>

                  {values.complaintAgainst !== "individual" && (
                    <>
                      {" "}
                      <Grid item>
                        <Typography
                          style={{
                            marginBottom: "5px",
                            fontSize: "16px",
                            fontFamily: "Cairo",
                          }}
                        >
                          {i18n.t("complaintForm3.callerPhoneNumberLabel")}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                          data-test-id="phoneNumber"
                          fullWidth
                          variant="outlined"
                          placeholder={i18n.t(
                            "complaintForm3.callerPhoneNumberLabel"
                          )}
                          value={this.state.phoneNumber}
                          onChange={(event) => {
                            this.onValueChange(
                              "phoneNumber",
                              event.target.value
                            );
                            setFieldValue("phoneNumber", event.target.value);
                          }}
                          sx={{
                            maxWidth: "410px",
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#B68A35",
                              },
                              "&:hover fieldset": {
                                borderColor: "#B68A35",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#B68A35",
                              },
                            },
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "phoneNumber")}
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{
                            marginBottom: "5px",
                            fontSize: "16px",
                            fontFamily: "Cairo",
                          }}
                        >
                          {i18n.t("complaintForm3.callerNameLabel")}
                        </Typography>
                        <TextField
                          data-test-id="caller_name"
                          fullWidth
                          variant="outlined"
                          placeholder={i18n.t("complaintForm3.callerNameLabel")}
                          value={this.state.callerName}
                          onChange={(event) => {
                            this.onValueChange(
                              "callerName",
                              event.target.value
                            );
                            setFieldValue("callerName", event.target.value);
                          }}
                          sx={{
                            maxWidth: "410px",
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#B68A35",
                              },
                              "&:hover fieldset": {
                                borderColor: "#B68A35",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#B68A35",
                              },
                            },
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "callerName")}
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{
                            marginBottom: "5px",
                            fontSize: "16px",
                            fontFamily: "Cairo",
                          }}
                        >
                          {i18n.t("complaintForm3.emirateLabel")}
                        </Typography>
                        <Select
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              borderColor: "#B68A35", // Set border color to B68A35 when focused
                              "&:hover": {
                                borderColor: "#B68A35", // Maintain the border color on hover
                              },
                              height: "56px", // Match TextField height
                              maxWidth: "410px",
                              width: "100%",
                            }),
                            menu: (provided) => ({
                              ...provided,
                              backgroundColor: "#FFF", // Change the dropdown background color to match the theme
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isSelected
                                ? "#B68A35"
                                : state.isFocused
                                ? "#FFF7E8"
                                : provided.backgroundColor, // Custom background on select/focus
                              color: state.isSelected ? "#FFFFFF" : "#000000", // Change text color when selected
                              "&:hover": {
                                backgroundColor: "#FFF7E8", // Change hover background color
                                color: "#000000",
                              },
                            }),
                          }}
                          data-test-id="emirateId"
                          classNamePrefix="react-select"
                          placeholder={i18n.t("complaintForm3.emirate")}
                          isSearchable={false}
                          value={
                            this.state.emirateListData.filter(
                              (option: Dropdown) =>
                                option.value === this.state.emirateId
                            )[0]
                          }
                          options={this.state.emirateListData}
                          onChange={(event) => {
                            const value = this.getValue(event);
                            this.onValueChange("emirateId", value);
                            setFieldValue("emirateId", value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "emirateId")}
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{
                            marginBottom: "5px",
                            fontSize: "16px",
                            fontFamily: "Cairo",
                          }}
                        >
                          {i18n.t("complaintForm3.dateOfCallLabel")}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              //     label="Date of call"
                              value={this.state.dateOfCall}
                              onChange={(value: any) =>
                                this.onValueChange("dateOfCall", value)
                              }
                              slotProps={{
                                textField: {
                                  sx: {
                                    maxWidth: "410px",
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: "#B68A35", // Set the initial border color
                                        label: "none",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "#B68A35", // Maintain color on hover
                                        label: "none",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#B68A35", // Keep color when focused
                                        label: "none",
                                      },
                                    },
                                  },
                                  error: false, // Explicitly set error to false to avoid red border
                                },
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        {/* {this.getErrorMessage(touched, errors, "dateOfCall")} */}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          style={{
                            marginBottom: "5px",
                            fontSize: "16px",
                            fontFamily: "Cairo",
                          }}
                        >
                          {i18n.t("complaintForm3.timeOfCallLabel")}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["TimePicker"]}>
                            <TimePicker
                              //      label="Time of call"
                              value={this.state.timeOfCall}
                              onChange={(value: any) =>
                                this.onValueChange("timeOfCall", value)
                              }
                              slotProps={{
                                textField: {
                                  sx: {
                                    maxWidth: "410px",
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: "#B68A35", // Set the initial border color
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "#B68A35", // Maintain color on hover
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#B68A35", // Keep color when focused
                                      },
                                    },
                                  },
                                  error: false,
                                },
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        {/* {this.getErrorMessage(touched, errors, "timeOfCall")} */}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          style={{
                            marginBottom: "5px",
                            fontSize: "16px",
                            fontFamily: "Cairo",
                          }}
                        >
                          {i18n.t("complaintForm3.additionalNotesLabel")}
                        </Typography>
                        <TextField
                          data-test-id="additionalNotes"
                          maxRows={4}
                          multiline
                          rows={4}
                          aria-label="maximum height"
                          placeholder={i18n.t(
                            "complaintForm3.additionalNotesLabel"
                          )}
                          variant="outlined"
                          value={this.state.additionalNotes}
                          onChange={(event) => {
                            this.onValueChange(
                              "additionalNotes",
                              event.target.value
                            );
                            setFieldValue(
                              "additionalNotes",
                              event.target.value
                            );
                          }}
                          sx={{
                            maxWidth: "410px",
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#B68A35",
                              },
                              "&:hover fieldset": {
                                borderColor: "#B68A35",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#B68A35",
                              },
                            },
                          }}
                        />
                        {this.getErrorMessage(
                          touched,
                          errors,
                          "additionalNotes"
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>

                {values.complaintAgainst !== "individual" && (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        this.props.navigation.navigate(
                          "ComplaintFormPersonalDetails"
                        )
                      }
                      variant="outlined"
                      style={{
                        fontFamily: "Cairo",
                        color: "#CBA344",
                        borderColor: "#CBA344",
                        marginRight: "20px",
                      }}
                    >
                      {i18n.t("complaintForm3.cancelButtonText")}
                    </Button>

                    <Button
                      sx={{
                        fontFamily: "Cairo",
                        backgroundColor: "#CBA344",
                        borderColor: "#CBA344",
                        "&:hover": {
                          backgroundColor: "#CBA344",
                          borderColor: "#CBA344",
                        },
                        width: "108px",
                        marginRight: "20px",
                      }}
                      type="submit"
                      variant="contained"
                    >
                      {i18n.t("complaintForm3.submitButtonText")}
                    </Button>
                  </Box>
                )}
              </form>
            )}
          </Formik>
          {this.state.complaintAgainst === "individual" && (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "60px",
              }}
            >
              {this.IndividualBorder()}
            </Box>
          )}
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

const complaint_category: Dropdown[] = [
  { label: i18n.t("complaintForm3.individual"), value: "individual" },
  { label: i18n.t("complaintForm3.company"), value: "company" },
];

const webStyle = {
  mainWrapper: {
    fontFamily: "Cairo",
  },
  pagination: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "end",
  },

  titleText: {
    fontFamily: "Cairo",
    fontSize: 40,
    paddingVertical: 10,
    fontWeight: "600",
  },
  bodyText: {
    fontFamily: "Cairo",
    fontSize: 16,
    paddingVertical: 15,
    fontWeight: "400",
  },
};
// Customizable Area End

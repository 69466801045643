import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import i18n from "../../../framework/src/languageSupport/i18n.web";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config.js");
const { baseURL } = require("../../../../packages/framework/src/config.js");
export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface Touched {
  prefix: boolean;
  callerName: boolean;
  phoneNumber: boolean;
  emirateId: boolean;
  email: boolean;
  dateOfCall: boolean;
  timeOfCall: boolean;
  complaintAgainst: boolean;
  additionalNotes: boolean;
  showDialog: boolean;
}

export interface Error {
  prefix: string;
  callerName: string;
  phoneNumber: string;
  emirateId: string;
  email: string;
  dateOfCall: any;
  timeOfCall: any;
  complaintAgainst: string;
  additionalNotes: string;
}

export interface EmirateListResponseJson {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  };
}

export interface Dropdown {
  label: string;
  value: string;
}

export interface Data {
  userId: string;
  prefix: string;
  caller_name: string;
  phone_number: string;
  email: string;
  emirateId: string;
  date_of_call: any;
  time_of_call: any;
  complaint_against: string;
  description_of_incident: string;
}
export interface EmirateListData {
  label: string;
  value: string;
}

// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  userId: string;
  callerName: string;
  phoneNumber: string;
  emirateListData: EmirateListData[];
  email: string;
  prefix: string;
  emirateId: string;
  dateOfCall: any;
  timeOfCall: any;
  complaintAgainst: string;
  additionalNotes: string;
  showDialog: boolean;
  isChange: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class ComplaintFormCallerDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserListApiCallId: string = "";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  getEmirateListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      userId: "",
      callerName: "",
      phoneNumber: "",
      email: "",
      emirateListData: [],
      prefix: "",
      emirateId: "",
      dateOfCall: "",
      timeOfCall: "",
      complaintAgainst: "",
      additionalNotes: "",
      isChange: false,
      showDialog: false,

      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!");
          this.setState({ loading: false });
        } else if (responseJson.errors) {
          toast.error(responseJson.errors);
          this.setState({ loading: false });
        } else if (apiRequestCallId == this.getEmirateListApiCallId) {
          let filterData = responseJson.data.data.map(
            (item: EmirateListResponseJson) => {
              return {
                label: item.attributes.name,
                value: item.attributes.id,
              };
            }
          );
          this.setState({ emirateListData: filterData, loading: false });
        } else if (apiRequestCallId == this.addUserApiCallId) {
          toast.success("User added successfully!");
          this.onCancel();
        } else if (apiRequestCallId == this.editUserApiCallId) {
          toast.success("User edited successfully!");
          this.setState({ isChange: false });
          this.onCancel();
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getEmirateList();
  }

  onValueChange = (name: string, value: string | boolean) => {
    this.setState({ ...this.state, [name]: value });
  };

  onEditBtnClicked = (item: Data) => {
    this.setState({
      userId: item.userId,
      prefix: item.prefix,
      callerName: item.caller_name,
      phoneNumber: item.phone_number,
      email: item.email,
      emirateId: item.emirateId,
      dateOfCall: item.date_of_call,
      timeOfCall: item.time_of_call,
      complaintAgainst: item.complaint_against,
      additionalNotes: item.description_of_incident,
    });
  };

  onCancel = () => {
    this.setState({
      userId: "",
      callerName: "",
      phoneNumber: "",
      email: "",
      prefix: "",
      emirateId: "",
      dateOfCall: "",
      timeOfCall: "",
      complaintAgainst: "",
      additionalNotes: "",
      showDialog: false,
    });
  };

  getEmirateList = () => {
    this.setState({ loading: true });

    const header = {};

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEmirateListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEmirateListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getEmirateListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  navigateToHomePage = () => {
    const { navigation }: any = this.props;
    const { navigate }: any = navigation;
    navigate("Home", { navigation });
  };

  submitComplaint = async () => {
    this.setState({ loading: true });

    const token = await localStorage.getItem("authToken");

    const lang = localStorage.getItem("preferredLanguage")
    // Prepare headers
    const headers: Record<string, string> = {};
    if (token) {
      headers["token"] = token; // Only add the token header if it's not null
    }

    const getEmiratesID: any = this.state.emirateId
      ? this.state.emirateId
      : localStorage.getItem("personalInformationEmirateID");

    // Create a new FormData object and append the form fields
    const formData = new FormData();
    formData.append("complaint_against", "company");
    formData.append("phone_number", this.state.phoneNumber);
    formData.append("company_name", this.state.callerName);
    formData.append("emirate_id", getEmiratesID);
    formData.append("date_of_call", this.state.dateOfCall);
    formData.append("time_of_call", this.state.timeOfCall);
    formData.append("description", this.state.additionalNotes);
    if(lang) {
      formData.append("language_preference", lang === "English" ? "english" : "arabic" );
    }


    const apiBaseUrl = baseURL;
    const finalApi = `${apiBaseUrl}/bx_block_complain/complains`;
    fetch(finalApi, {
      method: "POST", // HTTP method
      body: formData, // Send FormData as the body
      headers: headers,
    })
      .then((response) => response.json()) // Parse the JSON response
      .then((data) => {
        console.log("Success:", data); // Handle success
        this.setState({ loading: false }); // Update loading state
        this.showAlertNew(
          "Success",
          "Application submitted successfully",
          data.complain_number,
          "complaint",
          this.props,
          this.navigateToHomePage
        );
      })
      .catch((error) => {
        console.error("Error:", error); // Handle error
        this.setState({ loading: false }); // Update loading state
        this.showAlertNew(
          "Failed",
          "Application wasn’t submitted",
          "",
          "complaint"
        );
      });
  };

  // Customizable Area End
}

import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  SafeAreaView,
  Button,
  View
} from "react-native";
import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController";
import NewLandingPage from "../../../components/src/LandingPage";

// Customizable Area End

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
        <NewLandingPage></NewLandingPage>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  landingPageView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  landingPageText: {
    fontSize: 42,
    letterSpacing: 2,
    fontWeight: "bold",
    color: "#323441",
    marginTop: 15
  },
  mainContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff"
  }
});
// Customizable Area End

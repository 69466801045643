// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import * as Yup from "yup";
import Select, { ValueType } from "react-select";

import { Formik, FormikErrors, FormikTouched } from "formik";
import Spinner from "./components/Spinner.web";

import { Props, Touched, Error, Dropdown } from "./CustomFormController.web";
import ComplaintFormPersonalDetailsController from "./ComplaintFormPersonalDetailsController.web";

import CustomizedSteppers from "./components/Stepper.web";
import Captcha from "../../captcha/src/Captcha.web";
import i18n from '../../../framework/src/languageSupport/i18n.web';


// Customizable Area End

export default class ComplaintFormPersonalDetails extends ComplaintFormPersonalDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  userSchema = () => {
    if (this.getCurrentLanguage() == 'en') {

      return Yup.object().shape({
        prefix: Yup.string().required("*Prefix is required!"),
        fullName: Yup.string().required("*Full Name is required!"),
        phoneNumber: Yup.string()
          .required("*Phone number is required!")
          .matches(/^5\d{8}$/, "*Phone number must start with 5 and be exactly 9 digits long"),
        email: Yup.string().email(),
        emirateId: Yup.string().required("*Emirate is required!"),
      });
    } else {
      return Yup.object().shape({
        prefix: Yup.string().required("*اللقب مطلوب!"),
        fullName: Yup.string().required("*الاسم الكامل مطلوب!"),
        phoneNumber: Yup.string()
          .required("*رقم الهاتف مطلوب!")
          .matches(/^5\d{8}$/, "*يجب أن يبدأ رقم الهاتف بالرقم 5 وأن يكون مكونًا من 9 أرقام بالضبط"),
        email: Yup.string().email(),
        emirateId: Yup.string().required("*الإمارة مطلوبة!"),
      });
    }

  };

  getErrorMessage = (
    touched: FormikTouched<Touched>,
    errors: FormikErrors<Error>,
    value: string
  ) => {
    return (
      touched[value as keyof Error] &&
      errors[value as keyof Error] && (
        <Typography
          style={{
            marginTop: "2px",
            fontSize: "14px",
            color: "#f94b4b",
            fontFamily: "Cairo",
          }}
        >
          {errors[value as keyof Error]}
        </Typography>
      )
    );
  };

  onCaptchaVerified = (isVerified: boolean) => {
    this.setState({ isVerified: isVerified });
  };

  getValue = (event: ValueType<Dropdown, false>) => {
    return event ? event.value : "";
  };

  // Customizable Area End

  render() {
    const steps = this.getCurrentLanguage() == 'en' ? [
      "Personal Information",
      "Phone Verification",
      "Confirmation",
    ] : [
      "المعلومات الشخصية",
      "التحقق من الهاتف",
      "التأكيد",
    ];
    const activeStep = 0;
    const selectedLang = localStorage.getItem('preferredLanguage')
    const prefixList = selectedLang === "English" ? prefixListEn : prefixListAr;

    return (
      // Customizable Area Start
      <Box sx={webStyle.mainWrapper}>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <Box
          display={{ xs: "block", sm: "flex" }}
          justifyContent="space-between"
          mb="40px"
        ></Box>
        <Box padding={{ xs: "15px", sm: "20px", md: "30px" }}>
          <Typography sx={webStyle.titleText}>{i18n.t('logcomplaintpage1.complaintTitle')}</Typography>
          <Typography sx={webStyle.bodyText}>
            {i18n.t('logcomplaintpage1.complaintDescription')}
          </Typography>

          <Formik
            data-test-id="formik"
            initialValues={{
              prefix: this.state.prefix,
              fullName: this.state.fullName,
              phoneNumber: this.state.phoneNumber,
              email: this.state.email,
              emirateId: this.state.emirateId,
            }}
            validationSchema={this.userSchema}
            onSubmit={(values) => {
              this.registerUser();
            }}
          >
            {({ errors, touched, setFieldValue, handleSubmit }) => (
              <form
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "100%",
                }}
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
              >
                <CustomizedSteppers steps={steps} activeStep={activeStep} />

                <Grid
                  container
                  spacing={2}
                  direction="column"
                  style={{
                    maxWidth: "410px", // Set the max width to match child elements
                    margin: "0 auto", // Center horizontally
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        marginBottom: "5px",
                        fontSize: "16px",
                        fontFamily: "Cairo",
                      }}
                    >
                      {i18n.t('logcomplaintpage1.prefixLabel')}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Select
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: "#B68A35", // Set border color to B68A35 when focused
                          "&:hover": {
                            borderColor: "#B68A35", // Maintain the border color on hover
                          },
                          height: "56px", // Match TextField height
                          maxWidth: "410px",
                          width: "100%",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          backgroundColor: "#FFF", // Change the dropdown background color to match the theme
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#B68A35"
                            : state.isFocused
                            ? "#FFF7E8"
                            : provided.backgroundColor, // Custom background on select/focus
                          color: state.isSelected ? "#FFFFFF" : "#000000", // Change text color when selected
                          "&:hover": {
                            backgroundColor: "#FFF7E8", // Change hover background color
                            color: "#000000",
                          },
                        }),
                      }}
                      data-test-id="prefix"
                      classNamePrefix="react-select"
                      placeholder={selectedLang === "English" ? "Selected" : "اختر"}
                      isSearchable={false}
                      value={
                        prefixList.filter(
                          (option: Dropdown) =>
                            option.value === this.state.prefix
                        )[0]
                      }
                      options={prefixList}
                      onChange={(event) => {
                        const value = this.getValue(event);
                        this.onValueChange("prefix", value);
                        setFieldValue("prefix", value);
                      }}
                    />
                    {this.getErrorMessage(touched, errors, "prefix")}
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        marginBottom: "5px",
                        fontSize: "16px",
                        fontFamily: "Cairo",
                      }}
                    >
                      {i18n.t('logcomplaintpage1.fullNameLabel')}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      data-test-id="fullName"
                      fullWidth
                      variant="outlined"
                      placeholder={i18n.t('logcomplaintpage1.fullNameLabel')}
                      value={this.state.fullName}
                      onChange={(event) => {
                        this.onValueChange("fullName", event.target.value);
                        setFieldValue("fullName", event.target.value);
                      }}
                      sx={{
                        maxWidth: "410px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#B68A35",
                          },
                          "&:hover fieldset": {
                            borderColor: "#B68A35",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#B68A35",
                          },
                        },
                      }}
                    />
                    {this.getErrorMessage(touched, errors, "fullName")}
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        marginBottom: "5px",
                        fontSize: "16px",
                        fontFamily: "Cairo",
                      }}
                    >
                      {i18n.t('logcomplaintpage1.phoneNumberLabel')}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      data-test-id="phoneNumber"
                      fullWidth
                      variant="outlined"
                      placeholder={i18n.t('logcomplaintpage1.phoneNumberLabel')}
                      value={this.state.phoneNumber}
                      onChange={(event) => {
                        this.onValueChange("phoneNumber", event.target.value);
                        setFieldValue("phoneNumber", event.target.value);
                      }}
                      sx={{
                        maxWidth: "410px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#B68A35",
                          },
                          "&:hover fieldset": {
                            borderColor: "#B68A35",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#B68A35",
                          },
                        },
                      }}
                    />
                    {this.getErrorMessage(touched, errors, "phoneNumber")}
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        marginBottom: "5px",
                        fontSize: "16px",
                        fontFamily: "Cairo",
                      }}
                    >
                      {i18n.t('logcomplaintpage1.emirateLabel')}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Select
                      data-test-id="emirateId"
                      classNamePrefix="react-select"
                      placeholder={i18n.t('logcomplaintpage1.emirateLabel')}
                      isSearchable={false}
                      value={
                        this.state.emirateListData.filter(
                          (option: Dropdown) =>
                            option.value === this.state.emirateId
                        )[0]
                      }
                      options={this.state.emirateListData}
                      onChange={(event) => {
                        const value = this.getValue(event);
                        this.onValueChange("emirateId", value);
                        setFieldValue("emirateId", value);
                      }}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: state.isFocused
                            ? "#B68A35 !important"
                            : "#B68A35", // Ensure focus state uses the correct color
                          "&:hover": {
                            borderColor: "#B68A35 !important", // Make hover border match
                          },
                          boxShadow: state.isFocused
                            ? "0 0 0 2px #B68A35 !important"
                            : "none", // Ensure box shadow is correct on focus
                          outline: "none", // Remove default focus outline
                          height: "56px", // Keep height consistent
                          maxWidth: "410px",
                          width: "100%",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          backgroundColor: "#FFF", // Change the dropdown background color to match the theme
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#B68A35" // Background when selected
                            : state.isFocused
                            ? "#FFF7E8" // Background when focused
                            : provided.backgroundColor, // Default background
                          color: state.isSelected ? "#FFFFFF" : "#000000", // Text color
                          "&:hover": {
                            backgroundColor: "#FFF7E8", // Background on hover
                            color: "#000000",
                          },
                        }),
                      }}
                    />
                    {this.getErrorMessage(touched, errors, "emirateId")}
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        marginBottom: "5px",
                        fontSize: "16px",
                        fontFamily: "Cairo",
                      }}
                    >
                      {i18n.t('logcomplaintpage1.emailLabel')}
                    </Typography>
                    <TextField
                      data-test-id="email"
                      fullWidth
                      variant="outlined"
                      placeholder={i18n.t('logcomplaintpage1.emailLabel')}
                      value={this.state.email}
                      onChange={(event) => {
                        this.onValueChange("email", event.target.value);
                        setFieldValue("email", event.target.value);
                      }}
                      sx={{
                        maxWidth: "410px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#B68A35",
                          },
                          "&:hover fieldset": {
                            borderColor: "#B68A35",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#B68A35",
                          },
                        },
                      }}
                    />
                    {this.getErrorMessage(touched, errors, "email")}
                  </Grid>
                </Grid>

                <Box mt="30px" display="flex">
                  <Captcha onVerified={this.onCaptchaVerified} />
                </Box>

                <Box mt="30px" display="flex">
                  <Button
                    type="submit"
                    sx={{
                      fontFamily: "Cairo",
                      backgroundColor: "#B68A35",
                      borderColor: "#B68A35",
                      "&:hover": {
                        backgroundColor: "#B07830",
                        borderColor: "#B07830",
                      },
                      width: "108px",
                    }}
                    variant="contained"
                    disabled={!this.state.isVerified}
                  >
                    {i18n.t('logcomplaintpage1.nextButtonText')}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

const prefixListEn: Dropdown[] = [
  { label: "Mr.", value: "Mr." },
  { label: "Ms.", value: "Ms." },
  { label: "Mrs.", value: "Mrs." },
];

const prefixListAr: Dropdown[] = [
  { label: "السيد", value: "السيد" },
  { label: "الآنسة", value: "الآنسة" },
  { label: "السيدة", value: "السيدة" },
];

const webStyle = {
  mainWrapper: {
    fontFamily: "Cairo",
    padding: { xs: "10px", md: "20px" },
  },
  titleText: {
    fontSize: { xs: "24px", sm: "32px", md: "40px" }, // Responsive font size
    paddingVertical: 10,
    fontWeight: "600",
    fontFamily: "Cairo",
  },
  bodyText: {
    fontSize: { xs: "14px", sm: "16px", md: "18px" }, // Responsive font size
    paddingVertical: 15,
    fontWeight: "400",
    fontFamily: "Cairo",
  },
};
// Customizable Area End

import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config.js");

const { baseURL } = require("../../../../packages/framework/src/config.js");
export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface Touched {
  prefix: boolean;
  fullName: boolean;
  phoneNumber: boolean;
  emirateId: boolean;
  email: boolean;
}

export interface Error {
  prefix: string;
  fullName: string;
  phoneNumber: string;
  emirateId: string;
  email: string;
}

export interface EmirateListResponseJson {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  };
}

export interface Dropdown {
  label: string;
  value: string;
}

export interface EmirateListData {
  label: string;
  value: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  userId: string;
  emirateListData: EmirateListData[];
  fullName: string;
  phoneNumber: string;
  email: string;
  prefix: string;
  emirateId: string;
  isChange: boolean;
  isVerified: boolean;
  activeStep: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class ComplaintFormPersonalDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserListApiCallId: string = "";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  getEmirateListApiCallId: string = "";
  registerUserApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      userId: "",
      emirateListData: [],
      fullName: "",
      phoneNumber: "",
      email: "",
      prefix: "",
      emirateId: "",
      isChange: false,
      isVerified: false,
      activeStep: 0,

      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!");
          this.setState({ loading: false });
        } else if (responseJson.errors) {
          toast.error(responseJson.errors);
          this.setState({ loading: false });
        } else if (apiRequestCallId == this.getEmirateListApiCallId) {
          console.log(
            "getEmirateListApiEndPoint responseJson.data=>>",
            responseJson.data
          );

          const emirateListAr: Dropdown[] = [
            { label: "أبو ظبي", value: "1" },
            { label: "دبي", value: "2" },
            { label: "الشارقة", value: "3" },
            { label: "عجمان", value: "4" },
            { label: "أم القيوين", value: "5" },
            { label: "الفجيرة", value: "6" },
            { label: "رأس الخيمة", value: "7" }
          ];

          const selectedLang = localStorage.getItem('preferredLanguage')

          let filterData = responseJson.data.data.map(
            (item: EmirateListResponseJson) => {
              return {
                label: item.attributes.name,
                value: item.attributes.id,
              };
            }
          );
          this.setState({ emirateListData: selectedLang === "English" ? filterData : emirateListAr, loading: false });
        } else if (apiRequestCallId == this.registerUserApiCallId) {
          console.log(
            "registerUserApiEndPoint responseJson.data=>>",
            responseJson.data
          );
          this.setState({ loading: false });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getEmirateList();
  }

  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  };

  onCancel = () => {
    this.setState({
      userId: "",
      fullName: "",
      phoneNumber: "",
      email: "",
      prefix: "",
      emirateId: "",
    });
  };

  getEmirateList = () => {
    this.setState({ loading: true });

    const header = {};

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEmirateListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEmirateListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getEmirateListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  registerUser = () => {
    this.setState({ loading: true });

    // Create a new FormData object and append the form fields
    const formData = new FormData();
    formData.append("phone_number", this.state.phoneNumber); // phone_number value from the state
    formData.append("prefix", this.state.prefix); // prefix value from the state
    formData.append("full_name", this.state.fullName); // full_name value from the state
    formData.append("email", this.state.email); // email value from the state
    formData.append("emirate_id", this.state.emirateId); // emirate_id value from the state
    const apiBaseUrl = baseURL;
    const finalApi = `${apiBaseUrl}/account_block/accounts`;
    fetch(finalApi, {
      method: "POST", // HTTP method
      body: formData, // Send FormData as the body
    })
      .then((response) => response.json()) // Parse the JSON response
      .then((data) => {
        localStorage.setItem("phoneNumber", this.state.phoneNumber);
        localStorage.setItem("userId", data.data.data.id);
        localStorage.setItem(
          "personalInformationEmirateID",
          this.state.emirateId
        );
        console.log("Success: herer", data.data.data); // Handle success
        this.setState({ loading: false }); // Update loading state
        this.handleNext();
      })
      .catch((error) => {
        console.error("Error:", error); // Handle error
        this.setState({ loading: false }); // Update loading state
      });
  };

  handleNext = () => {
    const { navigation } = this.props;
    const { navigate }: any = navigation;
    navigate("ComplaintOtpVerfication", { navigation });
  };
}

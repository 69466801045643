// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import * as Yup from "yup";
import Select, { ValueType } from "react-select";
import { styled } from "@mui/styles";
import { Formik, FormikErrors, FormikTouched } from "formik";
import Spinner from "./components/Spinner.web";

import { Props, Touched, Error, Dropdown } from "./CustomFormController.web";
import TrackComplaintPersonalInfoController from "./TrackComplaintPersonalInfoController.web";
import CustomizedSteppers from "./components/Stepper.web";
import Captcha from "../../captcha/src/Captcha.web";
import i18n from '../../../framework/src/languageSupport/i18n.web';

// Customizable Area End

export default class TrackComplaintPersonalInfo extends TrackComplaintPersonalInfoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  userSchema = () => {
    if (this.getCurrentLanguage() == 'en') {
      return Yup.object().shape({
        complaintNumber: Yup.string().required("*Complaint number is required!"),
        phoneNumber: Yup.string()
          .required("*Phone number is required!")
          .matches(/^5\d{8}$/, "*Phone number must start with 5 and be exactly 9 digits long"),
      });
    }
    else {
      return Yup.object().shape({
        phoneNumber: Yup.string().required("*رقم الهاتف مطلوب!").matches(/^5\d{8}$/, "*يجب أن يبدأ رقم الهاتف بالرقم 5 وأن يكون مكوناً من 9 أرقام بالضبط"),
        complaintNumber: Yup.string()
        .required("*يرجى ادخال رقم الشكوى"),
      });
    }
  };

  onCaptchaVerified = (isVerified: boolean) => {
    this.setState({ isVerified: isVerified });
  };

  getErrorMessage = (
    touched: FormikTouched<Touched>,
    errors: FormikErrors<Error>,
    value: string
  ) => {
    return (
      touched[value as keyof Error] &&
      errors[value as keyof Error] && (
        <Typography
          style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b" }}
        >
          {errors[value as keyof Error]}
        </Typography>
      )
    );
  };

  getValue = (event: ValueType<Dropdown, false>) => {
    return event ? event.value : "";
  };

  // Customizable Area End

  render() {

    const steps = this.getCurrentLanguage() == 'en' ? [
      "Personal Information",
      "Phone Verification",
    ] : [
      "المعلومات الشخصية",
      "التحقق من الهاتف",
    ];

    const activeStep = 0;
    return (
      // Customizable Area Start
      <Box sx={webStyle.mainWrapper}>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <Box
          display={{ xs: "block", sm: "flex" }}
          justifyContent="space-between"
          mb="40px"
        ></Box>
        <Box padding={{ xs: "15px", sm: "20px", md: "30px" }}>
          <Typography sx={webStyle.titleText}>{i18n.t('inquiryPage1.trackComplaintTitle')}</Typography>
          <Typography sx={webStyle.bodyText}>
            {i18n.t('inquiryPage1.trackComplaintDescription')}
          </Typography>

          <Formik
            data-test-id="formik"
            initialValues={{
              complaintNumber: this.state.complaintNumber,
              phoneNumber: this.state.phoneNumber,
            }}
            validationSchema={this.userSchema}
            onSubmit={(values) => {
              this.complaintInquiry();
            }}
          >
            {({ errors, touched, setFieldValue, handleSubmit }) => (
              <form
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "100%",
                }}
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
              >
                <CustomizedSteppers steps={steps} activeStep={activeStep} />
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  style={{
                    maxWidth: "410px", // Set the max width to match child elements
                    margin: "0 auto", // Center horizontally
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        marginBottom: "5px",
                        fontSize: "16px",
                        fontFamily: "Cairo",
                      }}
                    >
                      {i18n.t('inquiryPage1.phoneNumberLabel')}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      data-test-id="phoneNumber"
                      fullWidth
                      variant="outlined"
                      placeholder={i18n.t('inquiryPage1.phoneNumberLabel')}
                      value={this.state.phoneNumber}
                      onChange={(event) => {
                        this.onValueChange("phoneNumber", event.target.value);
                        setFieldValue("phoneNumber", event.target.value);
                      }}
                      sx={{
                        maxWidth: "410px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#B68A35",
                          },
                          "&:hover fieldset": {
                            borderColor: "#B68A35",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#B68A35",
                          },
                        },
                      }}
                    />
                    {this.getErrorMessage(touched, errors, "phoneNumber")}
                  </Grid>

                  <Grid item>
                    <Typography
                      style={{
                        marginBottom: "5px",
                        fontSize: "16px",
                        fontFamily: "Cairo",
                      }}
                    >
                      {i18n.t('inquiryPage1.complaintNumberLabel')}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      data-test-id="complaintNumber"
                      fullWidth
                      variant="outlined"
                      placeholder={i18n.t('inquiryPage1.complaintNumberLabel')}
                      value={this.state.complaintNumber}
                      onChange={(event) => {
                        this.onValueChange(
                          "complaintNumber",
                          event.target.value
                        );
                        setFieldValue("complaintNumber", event.target.value);
                      }}
                      sx={{
                        maxWidth: "410px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#B68A35",
                          },
                          "&:hover fieldset": {
                            borderColor: "#B68A35",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#B68A35",
                          },
                        },
                      }}
                    />
                    {this.getErrorMessage(touched, errors, "complaintNumber")}
                  </Grid>
                </Grid>
                <Box mt="30px" display="flex">
                  <Captcha onVerified={this.onCaptchaVerified} />
                </Box>

                <Box mt="30px" display="flex">
                  <Button
                    type="submit"
                    sx={{
                      fontFamily: "Cairo",
                      backgroundColor: "#B68A35",
                      borderColor: "#B68A35",
                      "&:hover": {
                        backgroundColor: "#B07830",
                        borderColor: "#B07830",
                      },
                      width: "108px",
                    }}
                    variant="contained"
                    disabled={!this.state.isVerified}
                  >
                    {i18n.t('inquiryPage1.nextButtonText')}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

const webStyle = {
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    padding: { xs: "10px", md: "20px" },
  },
  titleText: {
    fontSize: { xs: "24px", sm: "32px", md: "40px" }, // Responsive font size
    paddingVertical: 10,
    fontWeight: "600",
    fontFamily: "Cairo",
  },
  bodyText: {
    fontSize: { xs: "14px", sm: "16px", md: "18px" }, // Responsive font size
    paddingVertical: 15,
    fontWeight: "400",
    fontFamily: "Cairo",
  },
};

// Customizable Area End
